<template>
    <div class="main">
        <van-cell-group :border="false">
            <van-cell center>
                <template #title>
                    <van-field required v-model="objData.spmc" placeholder="请输入商品名称" label="商品名称"/>
                </template>
            </van-cell>
            <van-cell center  is-link   >
                <template #title>
                    <van-field required readonly v-if="ShiforAdd == '0'" @click="sblbClick" v-model="objData.hwhlwmc" placeholder="请输入商品类别" label="商品类别"/>
                    <van-field required disabled v-else  v-model="objData.hwhlwmc" placeholder="请输入商品类别" label="商品类别"/>
                </template>
            </van-cell>
            <van-cell center  style="display:none;">
                <template #title>
                    <van-field v-model="objData.splb" placeholder="简称" label="简称"/>
                </template>
            </van-cell>
            <van-cell center is-link  >
                <template #title>
                    <van-field readonly required v-if="swzt.mslx=='正常税率'" v-model="objData.rate" @click="swzt.rateshow=true" placeholder="请选择税率" label="税率"/>
                    <van-field disabled required v-else v-model="objData.rate"  label="税率"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.gg" placeholder="请输入规格型号" label="规格型号"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.dw" placeholder="请输入计量单位" label="计量单位"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.hsje" placeholder="请输入金额" type="number" label="含税金额"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.hsdj" placeholder="请输入单价" type="number" label="含税单价"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.sl" placeholder="请输入数量" type="digit" label="数量"/>
                </template>
            </van-cell>
            <van-cell center style="display:none;">
                <van-cell center title="含税标志">
                    <template #right-icon>
                        <van-switch v-model="swzt.checkedhsbs" size="24" />
                    </template>
                </van-cell>
            </van-cell>
            <van-cell center is-link @click="swzt.lsllxshow=true">
                <template #title>
                    <van-field readonly v-model="swzt.mslx" placeholder="请选择免税类型" label="免税类型"/>
                </template>
            </van-cell>
            <!-- <van-cell center>
                <van-cell center title="享受优惠政策" v-show="swzt.sfshowzc">
                    <template #right-icon>
                        <van-switch v-model="swzt.checkedyhzc" size="24" />
                    </template>
                </van-cell>
            </van-cell> -->
            <van-cell center is-link v-if="swzt.checkedyhzc==true">
                <template #title>
                    <van-field readonly v-model="objData.yhzc" v-if="swzt.mslx=='正常税率'" @click="swzt.yhzcshow=true" placeholder="请选择优惠政策类型" label="优惠政策类型"/>
                    <van-field disabled v-model="objData.yhzc" v-else  label="优惠政策类型"/>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group title=" " :border="false">
            <van-cell center>
                <van-button type="primary" size="large"  @click="save()">保 存</van-button>
            </van-cell>
        </van-cell-group>

        <van-popup v-model:show="swzt.rateshow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="swzt.ratecolumns"
                @cancel="swzt.rateshow = false"
                @confirm="onConfirmRate"
            />
        </van-popup>
        <van-popup v-model:show="swzt.lsllxshow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="swzt.lsllxcolumns"
                @cancel="swzt.lsllxshow = false"
                @confirm="onConfirmLsllx"
            />
        </van-popup>
        <van-popup v-model:show="swzt.yhzcshow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="swzt.yhzccolumns"
                @cancel="swzt.yhzcshow = false"
                @confirm="onConfirmYhzc"
            />
        </van-popup>
    </div>
</template>

<script>
import { reactive,getCurrentInstance} from 'vue';
import {Dialog, Notify} from 'vant';
import Request from '../../api/commodity'
export default {
    name: "compAdd",
    setup(){
        const { proxy } = getCurrentInstance();
        console.log(proxy.$root.$route.query); 
        console.log(proxy.$root.$route.params);
        const objData = reactive({
            spmc: "",
            splb: "",
            rate: "1%",
            gg: "",
            dw: "",
            hsje: "",
            hsdj: "",
            sl: "",
            lsllx: "-1",
            spbm: "",
            yhzc: "未使用优惠政策",
            hsbs: "Y",
            HasPreferentialPolicy:"",
            id:"",
            hwhlwmc:""
        });
        const swzt = reactive({
            checkedhsbs:false,
            checkedyhzc:true,
            rateshow:false,
            ratecolumns:["0%", "1%", "3%", "4%", "5%", "6%", "9%", "10%", "11%", "13%", "16%", "17%"],
            lsllxshow:false,
            // lsllxcolumns:["非0税率", "出口退税","免税", "不征税", "普通零税率"],
            lsllxcolumns:["正常税率", "免税", "不征增值税", "普通零税率"],
            mslx:"正常税率",
            zclx:"",
            yhzcshow:false,
            // yhzccolumns:["免税", "不征税"],
            yhzccolumns:["未使用优惠政策" ,"简易征收" ,"按3%简易征收" ,"按5%简易征收" ,"100%先征后退" ,"50%先征后退" ,"不征税" ,"先征后退" ,"免税" ,"即征即退100%" ,"即征即退30%" ,"即征即退50%" ,"即征即退70%" ,"稀土产品" ,"超税负3%即征即退" ,"超税负8%即征即退" ,"超税负12%即征即退" ],
            sfshowzc:true,
        });
        const sesindata = sessionStorage.getItem("spxx-add-benyexx")!== null? JSON.parse(sessionStorage.getItem("spxx-add-benyexx")): null;
        const ShiforAdd = sessionStorage.getItem("isAdd")!== null? sessionStorage.getItem("isAdd"): null;
        console.log(sesindata);
        if(sesindata!=null){
            objData.spmc = sesindata.htdata.spmc;
            objData.splb = sesindata.htdata.splb;
            objData.rate = sesindata.htdata.rate;
            objData.gg = sesindata.htdata.gg;
            objData.dw = sesindata.htdata.dw;
            objData.sl = sesindata.htdata.sl;
            objData.hsje = sesindata.htdata.hsje;
            objData.hsdj = sesindata.htdata.hsdj;
            objData.hsbs = sesindata.htdata.hsbs;
            objData.lsllx = sesindata.htdata.lsllx;
            objData.id = sesindata.htdata.id;
            objData.hwhlwmc = sesindata.htdata.hwhlwmc;
            objData.HasPreferentialPolicy = sesindata.htdata.HasPreferentialPolicy;
            objData.yhzc = sesindata.htdata.yhzc;
            swzt.mslx = sesindata.qtdata.mslx;
            swzt.zclx = sesindata.qtdata.zclx;
            swzt.checkedyhzc = sesindata.qtdata.checkedyhzc;
            swzt.sfshowzc = sesindata.qtdata.sfshowzc;

        }
        const loadshuju= proxy.$root.$route.params; //前一页传入的数据
        if(JSON.stringify(loadshuju)!='{}'){
            objData.spmc = loadshuju.spmc;
            objData.splb = loadshuju.splb;
            objData.spbm = loadshuju.spbm;
            objData.rate = loadshuju.rate;
            objData.gg = loadshuju.gg;
            objData.dw = loadshuju.dw;
            objData.sl = loadshuju.sl;
            objData.hsje = loadshuju.hsje;
            objData.hsdj = loadshuju.hsdj;
            objData.hsbs = loadshuju.hsbs;
            objData.id = loadshuju.id;
            objData.hwhlwmc = loadshuju.hwhlwmc;
            objData.HasPreferentialPolicy = loadshuju.HasPreferentialPolicy;
            if(loadshuju.yhzc==""){
                swzt.sfshowzc=false;
                swzt.checkedyhzc=false;
            }else{
                swzt.checkedyhzc=true;
                swzt.sfshowzc=true;
                objData.yhzc=loadshuju.yhzc;
                // swzt.yhzccolumns=[];
                // swzt.yhzccolumns.push(loadshuju.yhzc);
            }
            objData.lsllx = loadshuju.lsllx;
            // if(loadshuju.lsllx=="0"){
            //     swzt.mslx="出口退税"
            // }else if(loadshuju.lsllx=="1"){
            //     swzt.mslx="免税"
            // }else if(loadshuju.lsllx=="2"){
            //     swzt.mslx="不征税"
            // }else if(loadshuju.lsllx=="3"){
            //     swzt.mslx="普通零税率"
            // }else{
            //     swzt.mslx="非0税率"
            // }
            if(loadshuju.lsllx=="-1"){
                swzt.mslx="正常税率"
            }else if(loadshuju.lsllx=="1"){
                swzt.mslx="免税"
            }else if(loadshuju.lsllx=="2"){
                swzt.mslx="不征增值税"
            }else if(loadshuju.lsllx=="3"){
                swzt.mslx="普通零税率"
            }
        }
        const backdata= proxy.$root.$route.query;   //选择商品类别返回的数据
        if(JSON.stringify(backdata)!='{}'){
            objData.splb = backdata.hbbm;
            objData.spbm = backdata.bm;
            objData.hwhlwmc = backdata.hwhlwmc;
            
            // if(backdata.zzstsgl!="" && backdata.zzstsgl!=undefined && backdata.zzstsgl!=null){
            //     swzt.yhzccolumns=[];
            //     swzt.yhzccolumns.push(backdata.zzstsgl);
            //     swzt.sfshowzc=true;
            // objData.yhzc=backdata.zzstsgl;
            // objData.rate = backdata.zzssl;
            // }else{
            //     swzt.yhzccolumns=[];
            //     swzt.yhzccolumns=["免税", "不征税"];
            //     swzt.sfshowzc=false;
            //     swzt.checkedyhzc==false;
            // }
        }
        const save = ()=> {
            // if(swzt.checkedhsbs==false){
            //     objData.hsbs='N';
            // }else{
                // objData.hsbs='Y';
            // }
            console.log(objData);

            if(swzt.checkedyhzc==false){
                objData.HasPreferentialPolicy='0';
            }else{
                objData.HasPreferentialPolicy='1';
            }
            if(objData.spmc==""){
                Notify({ type: 'warning', message: '请输入商品名称!' });
                return
            }
            if(objData.splb==""){
                Notify({ type: 'warning', message: '请选择商品类别!' });
                return
            }
            if(objData.rate==""){
                Notify({ type: 'warning', message: '请选择税率!' });
                return
            }
            if(objData.lsllx==""){
                objData.lsllx="-1"
            }
            if(JSON.stringify(loadshuju)=='{}'){
                Request.spgl_bc(objData)
                .then(res=>{
                    console.log('res=>',res);      
                    Dialog.alert({
                        message: res.returnMessage,
                    }).then(() => {
                        // proxy.$root.$router.push({path:"/commodity"});
                        proxy.$root.$router.go(-1);
                    });
                }).catch(err=>{
                    console.log('err=>',err);   
                })
            }else{
                Request.spgl_xg(objData)
                .then(res=>{
                    console.log('res=>',res);      
                    Dialog.alert({
                        message: res.returnMessage,
                    }).then(() => {
                        // proxy.$root.$router.push({path:"/commodity"});
                        proxy.$root.$router.go(-1);
                    });
                }).catch(err=>{
                    console.log('err=>',err);   
                })
            }
            
        };
        const onConfirmRate = (value, index)=> {
            console.log(value+"-"+index);
            objData.rate = value;
            swzt.rateshow = false;
        };
        const onConfirmLsllx = (value, index)=> {
            console.log(value+"-"+index);
            swzt.mslx = value;
            // objData.lsllx = index-1;
            // if(objData.lsllx < 0){
            //     objData.lsllx="";
            // }
            if(value == '正常税率'){
                objData.lsllx = '-1';
            }else if(value == '免税'){
                objData.lsllx = '1';
                objData.rate = '0%';
                objData.yhzc = '免税';
            }else if(value == '不征增值税'){
                objData.lsllx = '2';
                objData.rate = '0%';
                objData.yhzc = '不征税';
            }else if(value == '普通零税率'){
                objData.lsllx = '3';
                objData.rate = '0%';
                objData.yhzc = '未使用优惠政策';

            }
            swzt.lsllxshow = false;
        };
        const onConfirmYhzc = (value, index)=> {
            console.log(value+"-"+index);
            swzt.zclx = value;
            objData.yhzc = value;
            // objData.yhzc = index;
            swzt.yhzcshow = false;
            // swzt.ratecolumns.push(value);
            // objData.rate = value;
        };
        const sblbClick = ()=> {
            proxy.$root.$router.push({path:"/comdSpfl"});
            var obj={
                htdata:objData,
                qtdata:swzt
            }
            sessionStorage.setItem('spxx-add-benyexx',JSON.stringify(obj));
        }
        
        return{
            objData,
            save,
            swzt,
            onConfirmRate,
            onConfirmLsllx,
            onConfirmYhzc,
            sblbClick,
            ShiforAdd
        };
    }
}
</script>

<style scoped>
    .main {
        min-height: 100vh;
        /* position: relative; */
    }
</style>